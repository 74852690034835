@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  /* primary color */
  --scrollbar-color-track: #282c34;
  /* plain color w/ 25% opacity */
  --scrollbar-color-thumb: rgba(171, 178, 191, 0.25);
  --scrollbar-width: thin;
  --scrollbar-width-legacy: 10px;
}

body {
  @apply bg-slate-900 text-plain;
}

/* https://developer.chrome.com/docs/css-ui/scrollbar-styling#supporting_older_browser_versions */
/* Modern browsers with `scrollbar-*` support */
@supports (scrollbar-width: auto) {
  * {
    scrollbar-color: var(--scrollbar-color-thumb) var(--scrollbar-color-track);
    scrollbar-width: var(--scrollbar-width);
  }
}

/* Legacy browsers with `::-webkit-scrollbar-*` support */
@supports selector(::-webkit-scrollbar) {
  *::-webkit-scrollbar-thumb {
    background: var(--scrollbar-color-thumb);
  }
  *::-webkit-scrollbar-track {
    background: var(--scrollbar-color-track);
  }
  *::-webkit-scrollbar {
    max-width: var(--scrollbar-width-legacy);
    max-height: var(--scrollbar-width-legacy);
  }
}
